import React, { useState } from 'react'
import { BsInstagram } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Button, FormGroup, Input } from './Elements'
import { BounceLoader } from 'react-spinners'
import { realm } from '../helpers/realm'
import { connect } from 'react-redux'

const Footer = (props) => {
	const { user, path } = props
	const [email, setEmail] = useState('')
	const [submittingEmail, setSubmittingEmail] = useState(false)
	const [emailSuccess, setEmailSuccess] = useState(false)
	const [emailError, setEmailError] = useState('')
	const bounceLoaderColor = '#507f74'

	const handleEmailChange = (emailChange) => {
		setEmail(emailChange.target.value)
	}

	const handleSubscribe = async () => {
		setEmailError('')
		setEmailSuccess(false)
		setSubmittingEmail(true)
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

		if (re.test(email)) {
			const subscriberStatus = await realm.currentUser.callFunction(
				'mailChimp/AddUserToEmailList',
				{
					email: email,
					phoneNumber: user ? user.phone : '',
					firstName: user ? user.firstName : '',
					lastName: user ? user.lastName : '',
				}
			)
			if (subscriberStatus == 'error') {
				setSubmittingEmail(false)
				setEmailError('An error occured. Try again.')
			} else {
				setEmailSuccess(true)
				setSubmittingEmail(false)
			}
		} else {
			// invalid email, maybe show an error to the user.
			setSubmittingEmail(false)
			setEmailError('Invalid email address. Try again.')
		}
	}

	return (
		<>
			<div className='h-[600px] sm:hidden flex flex-col bg-gray-100 sm:mb-0'>
				<div className='flex flex-col w-full pt-5 bg-gray-100 items-center justify-center mx-auto'>
					<div className='text-sm'>Sign up for our newsletter</div>
					{submittingEmail ? (
						<BounceLoader
							className='m-auto'
							color={bounceLoaderColor}
						></BounceLoader>
					) : (
						<div className='w-full items-center justify-center mx-auto -ml-[30px]'>
							{path === '/' ? (
								<FormGroup className='w-full mx-auto'>
									<div className='flex flex-row w-full items-center justify-center mx-auto -mr-[30px]'>
										<Input
											placeholder='Enter your email'
											className={`pl-2 text-xs min-w-[250px]`}
											name={`newsletter`}
											error={false}
											type='email'
											onChange={handleEmailChange}
										/>
										<Button
											text='Subscribe'
											size='xs'
											color='green'
											className='-ml-[50px] px-2 flex items-center justify-center'
											onClick={handleSubscribe}
										/>
									</div>
								</FormGroup>
							) : (
								<FormGroup
									className='w-[250px] mx-auto'
									horizontal
								>
									<Input
										placeholder='Enter your email'
										className={`relative pl-5 text-xs`}
										name={`newsletter`}
										error={false}
										type='email'
										onChange={handleEmailChange}
									/>
									<Button
										text='Subscribe'
										size='xs'
										color='green'
										className='absolute ml-[200px] px-4'
										onClick={handleSubscribe}
									/>
								</FormGroup>
							)}
						</div>
					)}
					{emailError !== '' && (
						<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
							{emailError}
						</div>
					)}
					{emailSuccess && (
						<div className='text-green-400 mt-2 text-left font-semibold text-sm'>
							You've successfully subscribed!
						</div>
					)}
				</div>
				<div className='my-7 flex flex-row justify-center'>
					<div>
						<Link to='/'>
							<img
								className='m-auto text-black-default w-[100px] '
								src='/images/Small primary.svg'
								alt=''
							/>
						</Link>
					</div>
					<a
						href='https://www.instagram.com/deliverzer0/'
						target='_blank'
						rel='noreferrer'
					>
						<BsInstagram className='text-green-400 text-[44px] ml-3 h-[30px]' />
					</a>
					<a
						href='https://www.tiktok.com/@deliverzero'
						target='_blank'
						rel='noreferrer'
					>
						<FaTiktok className='ml-3 text-green-400 text-[40px] h-[30px]' />
					</a>
					<a
						href='https://apple.co/3ZsAqal'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='/images/App-store-badge.png'
							className='ml-3 w-[100px] '
						></img>
					</a>
				</div>
				<div className='mb-7 flex flex-row justify-center'>
					<div className='w-[100px] flex flex-col'>
						<Link
							to='/howitworks'
							className='text-sm'
						>
							How it Works
						</Link>
						<Link
							to='/whyreuse'
							className='mt-5 text-sm'
						>
							Why Reuse?
						</Link>
						<Link
							to='/aboutus'
							className='mt-5 text-sm'
						>
							About Us
						</Link>
						<Link
							to='/restaurant-admin/verification'
							className='mt-5 text-sm'
						>
							Merchant Dashboard
						</Link>
						<Link
							to='/reusables-shop'
							className='mt-5 text-sm'
						>
							Shop Reusables
						</Link>
					</div>
					<div className='ml-5 flex flex-col'>
						<Link
							to='/rewards-marketplace'
							className='text-sm'
						>
							Rewards Marketplace
						</Link>
						<Link
							to='/restaurantpartners'
							className='mt-5 text-sm'
						>
							For Restaurant Partners
						</Link>
						<Link
							to='/office-ordering/sign-up'
							className='mt-5 text-sm'
						>
							DeliverZero at Work
						</Link>
						<Link
							to='/giftCard'
							className='mt-5 text-sm'
						>
							DeliverZero Gift Cards
						</Link>
						<Link
							to='/press'
							className='mt-5 text-sm'
						>
							Press
						</Link>
						<Link
							to='/membership'
							className='mt-5 text-sm'
						>
							Membership
						</Link>
					</div>
				</div>
				<div className='flex flex-row justify-center'>
					<Link
						to='/privacy'
						className='text-xs text-gray-300'
					>
						Privacy Policy
					</Link>
					<Link
						to='/terms'
						className='ml-10 text-xs text-gray-300'
					>
						Terms of Use
					</Link>
				</div>
				<div className='flex mt-2 flex-row justify-center text-xs text-gray-300 mb-[100px]'>
					<div>DeliverZero &#169; {new Date().getFullYear()}</div>
					<div className='ml-3'>All rights reserved</div>
				</div>
			</div>
			<div className='px-[5%] lg:px-[10%] py-[0.75%] h-100 hidden sm:flex sm:flex-col bg-gray-100'>
				<div className='flex flex-row'>
					<div className='flex flex-col w-full justify-between'>
						<div className='flex flex-col md:flex-row w-full justify-center items-center mx-auto mt-5'>
							<div className='ml-3 text-sm flex content-center my-auto mr-3 ml-5'>
								Sign up for our newsletter
							</div>
							{submittingEmail ? (
								<BounceLoader
									className='m-auto'
									color={bounceLoaderColor}
								></BounceLoader>
							) : (
								<div>
									{path === '/' ? (
										<FormGroup
											className='w-full mx-3'
											footer
										>
											<div className='flex flex-row w-full'>
												<Input
													placeholder='Enter your email'
													className={`pl-5 text-xs min-w-[250px]`}
													name={`newsletter`}
													error={false}
													type='email'
													onChange={handleEmailChange}
												/>
												<Button
													text='Subscribe'
													size='xs'
													color='green'
													className='-ml-[50px] px-4 text-xs flex items-center justify-center'
													onClick={handleSubscribe}
												/>
											</div>
										</FormGroup>
									) : (
										<FormGroup
											className='w-[250px] mx-3'
											horizontal
										>
											<Input
												placeholder='Enter your email'
												className={`relative pl-5 text-xs`}
												name={`newsletter`}
												error={false}
												type='email'
												onChange={handleEmailChange}
											/>
											<Button
												text='Subscribe'
												size='xs'
												color='green'
												className='absolute ml-[200px] px-4'
												onClick={handleSubscribe}
											/>
										</FormGroup>
									)}
								</div>
							)}

							{emailError !== '' && (
								<div className='text-red-400 mt-2 text-left font-semibold text-sm'>
									{emailError}
								</div>
							)}
							{emailSuccess && (
								<div className='text-green-400 mt-2 text-left font-semibold text-sm'>
									You've successfully subscribed!
								</div>
							)}
						</div>
						<div className='hidden sm:flex my-3 flex-row content-center justify-center mx-auto'>
							<div>
								<Link to='/'>
									<img
										className='m-auto text-black-default w-[100px]'
										src='/images/Small primary.svg'
										alt=''
									/>
								</Link>
							</div>
							<a
								href='https://www.instagram.com/deliverzer0/'
								target='_blank'
								rel='noreferrer'
							>
								<BsInstagram className='ml-3 text-green-400 text-[44px] h-[30px]' />
							</a>
							<a
								href='https://www.tiktok.com/@deliverzero'
								target='_blank'
								rel='noreferrer'
							>
								<FaTiktok className='ml-3 text-green-400 text-[40px] h-[30px]' />
							</a>
							<a
								href='https://apple.co/3ZsAqal'
								target='_blank'
								rel='noreferrer'
							>
								<img
									src='/images/App-store-badge.png'
									className='ml-3 w-[100px]'
								></img>
							</a>
						</div>
						<div className='lg:hidden my-3 sm:flex justify-center w-full content-center'>
							<div className='min-w-[100px] flex flex-col'>
								<Link
									to='/howitworks'
									className='text-sm'
								>
									How it Works
								</Link>
								<Link
									to='/whyreuse'
									className='mt-2 text-sm'
								>
									Why Reuse?
								</Link>
								<Link
									to='/aboutus'
									className='mt-2 text-sm'
								>
									About Us
								</Link>
							</div>
							<div className='min-w-[150px] ml-10 lg:ml-12 flex flex-col'>
								<Link
									to='/reusables-shop'
									className='text-sm'
								>
									Shop Reusables
								</Link>
								<Link
									to='/restaurant-admin/verification'
									className='mt-2 text-sm'
								>
									Merchant Dashboard
								</Link>
								<Link
									to='/rewards-marketplace'
									className='mt-2 text-sm'
								>
									Rewards Marketplace
								</Link>
								<Link
									to='/giftCard'
									className='mt-2 text-sm'
								>
									DeliverZero Gift Cards
								</Link>
							</div>
							<div className='min-w-[100px] ml-10 lg:ml-12 flex flex-col'>
								<Link
									to='/restaurantpartners'
									className='text-sm'
								>
									For Restaurant Partners
								</Link>
								<Link
									to='/office-ordering/sign-up'
									className='mt-2 text-sm'
								>
									DeliverZero at Work
								</Link>
								<Link
									to='/press'
									className='mt-2 text-sm'
								>
									Press
								</Link>
								<Link
									to='/membership'
									className='mt-2 text-sm'
								>
									Membership
								</Link>
							</div>
						</div>
						<div className='sm:hidden lg:flex my-3 ml-5 lg:ml-10 flex-row justify-center w-full'>
							<div className='w-[100px] flex flex-col'>
								<Link
									to='/howitworks'
									className='text-sm'
								>
									How it Works
								</Link>
								<Link
									to='/whyreuse'
									className='mt-3 text-sm'
								>
									Why Reuse?
								</Link>
							</div>
							<div className='ml-5 lg:ml-12 flex flex-col'>
								<Link
									to='/aboutus'
									className='text-sm'
								>
									About Us
								</Link>
								<Link
									to='/restaurant-admin/verification'
									className='mt-3 text-sm'
								>
									Merchant Dashboard
								</Link>
							</div>
							<div className='ml-5 lg:ml-12 flex flex-col'>
								<Link
									to='/rewards-marketplace'
									className='text-sm'
								>
									Rewards Marketplace
								</Link>
								<Link
									to='/giftCard'
									className='mt-5 text-sm'
								>
									DeliverZero Gift Cards
								</Link>
							</div>
							<div className='ml-5 lg:ml-12 flex flex-col'>
								<Link
									to='/restaurantpartners'
									className='text-sm'
								>
									For Restaurant Partners
								</Link>
								<Link
									to='/office-ordering/sign-up'
									className='mt-3 text-sm'
								>
									DeliverZero at Work
								</Link>
							</div>
							<div className='ml-5 lg:ml-12 flex flex-col'>
								<Link
									to='/reusables-shop'
									className='text-sm'
								>
									Shop Reusables
								</Link>
								<Link
									to='/press'
									className='mt-3 text-sm'
								>
									Press
								</Link>
								<Link
									to='/membership'
									className='mt-3 text-sm'
								>
									Membership
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className='flex flex-row mt-5 w-full'>
					<div className='w-[230px] flex flex-row text-xs text-gray-300 items-center'>
						<div>DeliverZero &#169; {new Date().getFullYear()}</div>
						<div className='ml-3'>All rights reserved</div>
					</div>
					<div className='flex flex-row'>
						<Link
							to='/privacy'
							className='ml-10 text-xs text-gray-300'
						>
							Privacy Policy
						</Link>
						<Link
							to='/terms'
							className='ml-10 text-xs text-gray-300'
						>
							Terms of Use
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}
const mapStateToProps = ({ User, Path }) => ({
	user: User.user,
	path: Path.path,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
