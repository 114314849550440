import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getUser,
	getTransactions,
	getAppInfo,
	getNodeByAdminCodeOrSlug,
	updateUser,
} from '../../store/actions'
import { Footer } from '../../components'
import Header from '../../components/Header'
import { BounceLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

const PlatformIntegrationHome = (state) => {
	const { user, onGetUser, onGetNodeBySlugAdmin, node } = state

	const bounceLoaderColor = '#507f74'
	let navigate = useNavigate()
	const [selectedPlatform, setSelectedPlatform] = useState(null)

	useEffect(() => {
		onGetUser()
	}, [onGetUser])

	useEffect(() => {
		if (selectedPlatform) {
			navigate(
				`/restaurant-admin/platform-integration/${selectedPlatform}`
			)
		}
	}, [selectedPlatform])

	useEffect(() => {
		if (user) {
			// console.log(user.restaurantAdmin)
			onGetNodeBySlugAdmin(user.restaurantAdmin)
		}
	}, [user, user?.restaurantAdmin])

	if (!user)
		return (
			<div className='flex items-center h-screen'>
				<BounceLoader
					className='m-auto'
					color={bounceLoaderColor}
				></BounceLoader>
			</div>
		)

	if (user.restaurantAdmin) {
		if (node && node.slug === user.restaurantAdmin) {
			return (
				<div>
					<Header />
					<>
						<div
							className='underline font-bold my-4 cursor-pointer ml-6'
							onClick={() => {
								navigate('/restaurant-admin/home')
							}}
						>
							&#8592; Go to Dashboard Home
						</div>
						<div
							className='underline font-bold my-4 cursor-pointer ml-6'
							onClick={() => {
								navigate(-1)
							}}
						>
							&#8592; Go Back
						</div>
						<h1 className='font-header text-green-600 text-2xl md:text-4xl mb-9 mt-14 text-center'>
							Platform Integration
						</h1>
						<div className='w-[50%] mx-auto mb-4 max-w-[800px] text-lg text-center'>
							Complete your DeliverZero connection by adding
							DeliverZero to your online ordering platforms. Click
							on the platform below to see how to add DeliverZero.
						</div>
						<div className='w-[50%] mx-auto mb-10 max-w-[800px] text-sm text-center'>
							Questions or need help integrating with a platform?
							Contact DeliverZero by email at{' '}
							<a
								href='mailto:restaurants@deliverzero.com'
								className='text-green-600 underlined'
							>
								restaurants@deliverzero.com
							</a>{' '}
							or by calling{' '}
							<a
								href='tel:+18333380985'
								className='text-green-600 underlined'
							>
								(833)338-0985
							</a>
						</div>
						<div className='w-full flex justify-center items-center mb-10'>
							<div
								className='grid gap-5 grid-cols-1 md:grid-cols-3 flex flex-wrap justify-center items-center z-20 mb-8 my-auto max-w-[1200px]'>
								<div
									onClick={() => {
										setSelectedPlatform('ubereats')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/ubereats.png'
										alt='ubereats logo'
										className='w-[140px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('doordash')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/doordash.png'
										alt='doordash logo'
										className='w-[220px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('toast')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/toast.png'
										alt='toast logo'
										className='w-[120px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('square')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='https://logos-world.net/wp-content/uploads/2020/10/Square-Logo.png'
										alt='square logo'
										className='w-[200px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('chownow')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/chownow.png'
										alt='chownow logo'
										className='w-[200px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('otter')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/otter.png'
										alt='otter logo'
										className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('deliverect')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/deliverect.png'
										alt='deliverect logo'
										className='w-[220px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('grubhub')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/grubhub.png'
										alt='grubhub logo'
										className='w-[180px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('itsacheckmate')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<img
										src='/images/PlatformPartners/itsacheckmate-large.png'
										alt='itsacheckmate logo'
										className='w-[100px] h-auto my-auto mx-auto flex justify-center items-center'
									/>
								</div>
								<div
									onClick={() => {
										setSelectedPlatform('manual')
									}}
									className='h-[150px] w-[300px] shadow-light-grey flex flex-col m-auto rounded-[10px] cursor-pointer'
								>
									<div className='flex justify-center items-center text-2xl font-header my-auto'>
										Manual Entry
									</div>
								</div>
							</div>
						</div>
						<div className='text-lg flex flex-center justify-center mx-auto w-[50%]'></div>
					</>

					<Footer/>
				</div>
			)
		} else {
			return (
				<div className='flex items-center h-screen'>
					<BounceLoader
						className='m-auto'
						color={bounceLoaderColor}
					></BounceLoader>
				</div>
			)
		}
	} else {
		navigate('/')
	}
}

const mapStateToProps = ({Transactions, User, AppInfo, Nodes }) => ({
	transactions: Transactions.transactions,
	transactionsCount: Transactions.transactionsCount,
	loadingTransactions: Transactions.loadingTransactions,
	user: User.user,
	loadingUser: User.loading,
	appInfo: AppInfo.appInfo,
	node: Nodes.restaurantAdminNode,
	loadingNode: Nodes.loadingNode,
})

const mapDispatchToProps = (dispatch) => ({
	onGetTransactions: (payload) => dispatch(getTransactions(payload)),
	onGetUser: () => dispatch(getUser()),
	onGetAppInfo: () => dispatch(getAppInfo()),
	onGetNodeBySlugAdmin: (slug) => dispatch(getNodeByAdminCodeOrSlug(slug)),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlatformIntegrationHome)
