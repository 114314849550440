import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const FeaturedIn = () => {
	return (
		<>
			<div className='h-auto flex flex-col justify-center items-center mt-12'>
				<Link to='/press'>
					<h1 className='font-header text-green-600 text-3.5xl sm:text-5xl text-green-600 mb-6'>
						Featured in
					</h1>
				</Link>
				<div className='hidden sm:grid grid-cols-2 gap-8 xs:gap-8 sm:gap-10 md:grid-cols-4 mx-6 lg:flex lg:flex-wrap lg:justify-center lg:items-center lg:max-w-[1200px]'>
					<a
						href='https://abc17news.com/money/2021/03/22/this-startup-aims-to-take-the-trash-out-of-takeout/'
						target='_blank'
						className='h-18 w-24 block'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/abc.svg'
							alt='abc logo'
							className='h-auto max-w-[60px] my-5'
						/>
					</a>
					<a
						href='https://www.cnn.com/2021/03/22/business/deliverzero-takeout-food-waste-startup/index.html'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/cnn.svg'
							alt='cnn logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.fastcompany.com/90591190/this-new-delivery-service-cuts-down-on-takeout-waste-by-sending-your-food-in-reusable-packaging'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/fastcompany.svg'
							alt='fast company logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.forbes.com/sites/jeffkart/2021/07/29/the-future-of-takeout-is-plastic-free/?sh=666b2895e40c'
						target='_blank'
						className='block h-18 w-24'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Forbes.svg'
							alt='forbes logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.fox5ny.com/news/restaurants-reduce-waste-by-delivering-food-in-reusable-containers'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Fox5.svg'
							alt='fox5 logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://mashable.com/video/deliverzero-zero-waste-take-out'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Mashable.svg'
							alt='mashable logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://goop.com/wellness/environmental-health-civics/climate-optimism/'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Goop.svg'
							alt='goop logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.youtube.com/watch?v=bDS-JSsoG6Q'
						target='_blank'
						className='h-18 w-24 block flex justify-center align-center'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Now_This.svg'
							alt='now this logo'
							className='h-auto max-w-[60px] my-5'
						/>
					</a>
					<a
						href='https://www.nbcnewyork.com/news/ny-business-aims-to-eliminate-takeout-waste/2293765/'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/WNBC_4_NY.svg'
							alt='wnbc 4 new york logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.weforum.org/agenda/2020/01/how-this-new-company-is-taking-the-waste-out-of-restaurant-delivery/'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/World_Economic_Forum_logo.svg'
							alt='World Economic Forum logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.thrillist.com/eat/nation/deliverzero-sustainable-takeout-mission'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/thrillist.svg'
							alt='thrillist logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
					<a
						href='https://www.wsj.com/articles/take-the-guilt-out-of-takeout-11610641855?mod=hp_listc_pos1'
						target='_blank'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/wall-street-journal.svg'
							alt='wall street journal logo'
							className='h-auto max-w-[100px] my-5'
						/>
					</a>
				</div>
				<div className='sm:hidden grid grid-cols-4 gap-3 xs:gap-8 mx-6 min-h-[250px]'>
					<a
						href='https://abc17news.com/money/2021/03/22/this-startup-aims-to-take-the-trash-out-of-takeout/'
						target='_blank'
						className='h-full w-full block flex justify-center items-center'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/abc.svg'
							alt='abc logo'
							className=' max-w-[30px] my-5'
						/>
					</a>
					<a
						href='https://www.cnn.com/2021/03/22/business/deliverzero-takeout-food-waste-startup/index.html'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/cnn.svg'
							alt='cnn logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.fastcompany.com/90591190/this-new-delivery-service-cuts-down-on-takeout-waste-by-sending-your-food-in-reusable-packaging'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/fastcompany.svg'
							alt='fast company logo'
							className='max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.forbes.com/sites/jeffkart/2021/07/29/the-future-of-takeout-is-plastic-free/?sh=666b2895e40c'
						target='_blank'
						className='block h-18 w-24 flex justify-center items-center'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Forbes.svg'
							alt='forbes logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.fox5ny.com/news/restaurants-reduce-waste-by-delivering-food-in-reusable-containers'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/Fox5.svg'
							alt='fox5 logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://mashable.com/video/deliverzero-zero-waste-take-out'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/Mashable.svg'
							alt='mashable logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://goop.com/wellness/environmental-health-civics/climate-optimism/'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/Goop.svg'
							alt='goop logo'
							className=' max-w-[50px] my-5 '
						/>
					</a>
					<a
						href='https://www.youtube.com/watch?v=bDS-JSsoG6Q'
						target='_blank'
						className='h-full w-full block flex justify-center items-center'
						rel='noreferrer'
					>
						<img
							src='images/MediaSVGs/Now_This.svg'
							alt='now this logo'
							className=' max-w-[30px] my-5'
						/>
					</a>
					<a
						href='https://www.nbcnewyork.com/news/ny-business-aims-to-eliminate-takeout-waste/2293765/'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/WNBC_4_NY.svg'
							alt='wnbc 4 new york logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.weforum.org/agenda/2020/01/how-this-new-company-is-taking-the-waste-out-of-restaurant-delivery/'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/World_Economic_Forum_logo.svg'
							alt='World Economic Forum logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.thrillist.com/eat/nation/deliverzero-sustainable-takeout-mission'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/thrillist.svg'
							alt='thrillist logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
					<a
						href='https://www.wsj.com/articles/take-the-guilt-out-of-takeout-11610641855?mod=hp_listc_pos1'
						target='_blank'
						rel='noreferrer'
						className='flex justify-center items-center'
					>
						<img
							src='images/MediaSVGs/wall-street-journal.svg'
							alt='wall street journal logo'
							className=' max-w-[50px] my-5'
						/>
					</a>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = ({}) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedIn)
