import React from 'react'
import { Link } from 'react-router-dom'

function Button({
	className = '',
	text = 'button',
	size = 'base',
	link,
	icon = null,
	iconPosition = 'left',
	submit = false,
	full = false,
	color = 'green',
	href,
	...newProps
}) {
	let finalClass = `${className} items-center font-poppins tracking-wider cursor-pointer border border-2 rounded-full whitespace-nowrap  min-w-fit text-center justify-center focus:ring-2 focus:ring-offset-2 `

	if (size === 'sm') finalClass += ' text-md py-2 sm:px-6'
	else if (size === 'xs') finalClass += ' text-xs py-1 px-4 font-semibold'
	else if (size === 'base')
		finalClass += ' text-xl font-semibold py-3 sm:px-8'

	if (color === 'green')
		finalClass +=
			' text-white bg-green-600 border-green-600 hover:bg-green-400 hover:border-green-400 focus:ring-green-600'
	if (color === 'green-light')
		finalClass +=
			' text-white bg-green-400 border-green-400 hover:bg-white hover:text-green-400 hover:border-green-400 focus:ring-green-400'
	else if (color === 'green-outlined')
		finalClass +=
			' text-green-600 bg-transparent border-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white focus:ring-green-600'
	else if (color === 'green-white-fill')
		finalClass +=
			' text-green-600 bg-white border-green-600 hover:bg-green-600 hover:border-green-600 hover:text-white focus:ring-green-600'
	else if (color === 'gray')
		finalClass +=
			' text-gray-300 bg-transparent border-gray-300 hover:bg-gray-300 hover:border-gray-300 hover:text-white focus:ring-gray-300'
	else if (color === 'red')
		finalClass +=
			' text-white bg-red-400 border-red-400 hover:bg-white hover:border-red-400 hover:text-red-400 focus:ring-red-400'
	else if (color === 'red-outlined')
		finalClass +=
			' text-red-400 bg-transparent border-red-400 hover:bg-red-400 hover:text-white focus:ring-red-400'
	else if (color === 'toast')
		finalClass +=
			' text-white bg-[#ff4c00] border-[#ff4c00] hover:bg-white hover:text-[#ff4c00] focus:ring-[#ff4c00]'
	else if (color === 'ubereats')
		finalClass +=
			' text-white bg-[#000000] border-[#000000] hover:bg-white hover:text-[#000000] focus:ring-[#000000]'
	else if (color === 'caviar')
		finalClass +=
			' text-white bg-[#f87242] border-[#f87242] hover:bg-white hover:text-[#f87242] focus:ring-[#f87242]'
	else if (color === 'doordash')
		finalClass +=
			' text-white bg-[#eb1700] border-[#eb1700] hover:bg-white hover:text-[#eb1700] focus:ring-[#eb1700]'
	else if (color === 'chownow')
		finalClass +=
			' text-white bg-[#fe4f57] border-[#fe4f57] hover:bg-white hover:text-[#fe4f57] focus:ring-[#fe4f57]'
	else if (color === 'whole-foods')
		finalClass +=
			' text-white bg-[#00674B] border-[#00674B] hover:bg-white hover:text-[#00674B] focus:ring-[#00674B]'
	else if (color === 'disabled')
		finalClass += ' text-gray-300 bg-transparent border-gray-300'

	if (full) finalClass += ' w-full'
	let content = text
	if (icon) {
		if (iconPosition === 'left')
			content = (
				<>
					{React.cloneElement(icon, {
						className: 'mr-2 inline-block',
					})}
					{text}
				</>
			)
		else if (iconPosition === 'right')
			content = (
				<>
					{text}
					{React.cloneElement(icon, {
						className: 'ml-2 inline-block',
					})}
				</>
			)
		else if (iconPosition === 'center')
			content = (
				<>
					{text}
					{React.cloneElement(icon, {
						className: 'm-0 inline-block',
					})}
				</>
			)
	}
	let ButtonTag = href ? 'a' : 'div'
	if (link) {
		return (
			<Link
				to={link}
				className={finalClass}
				type={submit ? 'submit' : ''}
				{...newProps}
			>
				{content}
			</Link>
		)
	}
	if (ButtonTag === 'a') {
		return (
			<ButtonTag
				href={href}
				target='_blank'
				className={finalClass}
				type={submit ? 'submit' : ''}
				{...newProps}
			>
				{content}
			</ButtonTag>
		)
	}
	if (submit) ButtonTag = 'button'
	return (
		<ButtonTag
			className={finalClass}
			type={submit ? 'submit' : ''}
			{...newProps}
		>
			{content}
		</ButtonTag>
	)
}

export default Button
