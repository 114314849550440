import { realm } from '../helpers/realm'
import * as Realm from 'realm-web'

export const findAppInfo = async () => {
	if (!realm.currentUser) {
		const credentials = Realm.Credentials.anonymous()
		const logInResult = await realm.logIn(credentials)
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const appInfo = client.db('caas').collection('app_info')
	return appInfo.findOne({ version: 1 })
}

export const findBannerInfo = async () => {
	if (!realm.currentUser) {
		const credentials = Realm.Credentials.anonymous()
		const logInResult = await realm.logIn(credentials)
	}
	const client = realm.currentUser.mongoClient('RealmService')
	const bannerInfo = client.db('caas').collection('banner_info')
	return bannerInfo.findOne({ banner: true })
}

export const hideBanner = async () => {
	return true
}

export const executeMongoFunctionApi = async (payload) => {
	const response = await realm.currentUser.callFunction(
		payload.functionName,
		payload.payload
	)
	return response
}