import React from 'react'
import { Link } from 'react-router-dom'
import {
	Header,
	Hero,
	Footer,
	HowItWorks,
	WhyReuse,
	Partners,
	CreateAccount,
} from '../components'
import FeaturedIn from '../components/Homepage/FeaturedIn'
import PlatformPartners from '../components/Homepage/PlatformPartners'

function Home() {
	return (
		<div className='h-screen overflow-scroll'>
			<Header className='z-50' />
			<Hero />
			<div className='lg:transform lg:-translate-y-24 xl:-translate-y-36 z-0'>
				<svg
					id='visual'
					viewBox='0 0 1920 400'
					className='w-full'
				>
					<path
						d='M0 287L45.7 278.7C91.3 270.3 182.7 253.7 274.2 241.2C365.7 228.7 457.3 220.3 548.8 239.2C640.3 258 731.7 304 823 308C914.3 312 1005.7 274 1097 271C1188.3 268 1279.7 300 1371.2 324.5C1462.7 349 1554.3 366 1645.8 356.7C1737.3 347.3 1828.7 311.7 1874.3 293.8L1920 276L1920 0L1874.3 0C1828.7 0 1737.3 0 1645.8 0C1554.3 0 1462.7 0 1371.2 0C1279.7 0 1188.3 0 1097 0C1005.7 0 914.3 0 823 0C731.7 0 640.3 0 548.8 0C457.3 0 365.7 0 274.2 0C182.7 0 91.3 0 45.7 0L0 0Z'
						fill='#ffd378'
						strokeLinecap='round'
						strokeLinejoin='miter'
					></path>
				</svg>
			</div>
			<div className='z-10 -mb-10 lg:-mt-32 xl:-mt-48'>
				<HowItWorks />
			</div>
			<div className='z-20'>
				<PlatformPartners />
			</div>
			<div
				className='transform -translate-y-[55rem] xs:-translate-y-[60rem] sm:-translate-y-[60rem]
			lg:-translate-y-[48rem] xl:-translate-y-[52rem] z-10 -mb-[65rem] lg:-mb-[50rem]'
			>
				<div className=' bg-[#80BEAF] lg:h-[650px] xl:h-[700px] 2xl:h-[650px] h-[55rem]'></div>
				<svg
					id='visual'
					viewBox='0 0 1920 400'
					className='w-full'
				>
					<path
						d='M0 287L45.7 278.7C91.3 270.3 182.7 253.7 274.2 241.2C365.7 228.7 457.3 220.3 548.8 239.2C640.3 258 731.7 304 823 308C914.3 312 1005.7 274 1097 271C1188.3 268 1279.7 300 1371.2 324.5C1462.7 349 1554.3 366 1645.8 356.7C1737.3 347.3 1828.7 311.7 1874.3 293.8L1920 276L1920 0L1874.3 0C1828.7 0 1737.3 0 1645.8 0C1554.3 0 1462.7 0 1371.2 0C1279.7 0 1188.3 0 1097 0C1005.7 0 914.3 0 823 0C731.7 0 640.3 0 548.8 0C457.3 0 365.7 0 274.2 0C182.7 0 91.3 0 45.7 0L0 0Z'
						fill='#80BEAF'
						strokeLinecap='round'
						strokeLinejoin='miter'
					></path>
				</svg>
			</div>
			<WhyReuse />
			<Partners />
			<FeaturedIn />
			<div className='z-0 rotate-180 lg:transform lg:translate-y-12 lg:-mt-19'>
				<svg
					id='visual'
					viewBox='0 0 1920 400'
					className='w-full z-0'
				>
					<path
						d='M0 287L45.7 278.7C91.3 270.3 182.7 253.7 274.2 241.2C365.7 228.7 457.3 220.3 548.8 239.2C640.3 258 731.7 304 823 308C914.3 312 1005.7 274 1097 271C1188.3 268 1279.7 300 1371.2 324.5C1462.7 349 1554.3 366 1645.8 356.7C1737.3 347.3 1828.7 311.7 1874.3 293.8L1920 276L1920 0L1874.3 0C1828.7 0 1737.3 0 1645.8 0C1554.3 0 1462.7 0 1371.2 0C1279.7 0 1188.3 0 1097 0C1005.7 0 914.3 0 823 0C731.7 0 640.3 0 548.8 0C457.3 0 365.7 0 274.2 0C182.7 0 91.3 0 45.7 0L0 0Z'
						fill='#f38686'
						strokeLinecap='round'
						strokeLinejoin='miter'
					></path>
				</svg>
			</div>
			<CreateAccount className='z-10' />
			<Footer />
		</div>
	)
}

export default Home
